import React, { useState, useEffect } from "react";
import { getUser } from "../../utils/api";
import { useParams, useRouteMatch } from "react-router-dom";
import { URL } from "../../utils/constants";
import styles from "./styles.module.css";
import { FaRegCopy } from "react-icons/fa";

const LineSectionCard = ({ loadItem, updateOrders }) => {
  const { name } = useParams();
  const [lineSection, setLineSection] = useState({ details: [] });
  const { url } = useRouteMatch();
  const user = getUser;
  const [admin, setAdmin] = useState(false);
  const token = localStorage.getItem("auth_token");

  useEffect(() => {
    fetch(`${URL}/api/linesections/${name}/`, {
      method: "GET",
      headers: {
        authorization: token ? `Token ${token}` : null,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLineSection({ details: data });
      })
      .catch((error) => console.error("Error:", error));
  }, [name, token]);

  const copyTextToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log("Текст успешно скопирован в буфер обмена!");
    } catch (err) {
      console.error("Ошибка:", err);
    }
  };

  const arr = lineSection.details.archive || [];

  const groupedLinks = {
    tDrive: [],
    pDrive: [],
    others: [],
  };

  arr.forEach((element) => {
    const link = element["archive_link"];
    if (link.startsWith("T:")) {
      groupedLinks.tDrive.push(element);
    } else if (link.startsWith("P:")) {
      groupedLinks.pDrive.push(element);
    } else {
      groupedLinks.others.push(element);
    }
  });

  const renderLinks = (links) => (
      
        links.map((element, index) => (
          <ul key={index}>
            <a href="#" style={{ color: "black", marginRight: "8px" }}>{element["representation_name"]}</a>
            <FaRegCopy
              style={{ cursor: "pointer" }}
              title="Копировать ссылку"
              onClick={() => copyTextToClipboard(element["archive_link"])}
            />
          </ul>
        ))
      
    );

  return (
    <div className={styles["card"]}>
      <ul style={{ color: "black" }}>{lineSection.details.name}</ul>
      <ul style={{ color: "black" }}>{lineSection.details.line}</ul>
      
      {groupedLinks.tDrive.length > 0 && (
        <div>
          <h3>Ссылки на рабочем сервере</h3>
          {renderLinks(groupedLinks.tDrive)}
        </div>
      )}
      {groupedLinks.pDrive.length > 0 && (
        <div>
          <h3>Ссылки на удаленном сервере</h3>
          {renderLinks(groupedLinks.pDrive)}
        </div>
      )}
      {groupedLinks.others.length > 0 && (
        <div>
          <h3>Другие ссылки</h3>
          {renderLinks(groupedLinks.others)}
        </div>
      )}
    </div>
  );
};

export default LineSectionCard;