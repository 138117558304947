import React from "react";
import { getUser } from "../../utils/api";
import { useState, useEffect, useCallback } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { URL } from "../../utils/constants";
import { LinkComponent } from '../'
import styles from './styles.module.css'
import { FaRegCopy } from "react-icons/fa";

const HermodoorsCard = ({ loadItem, updateOrders }) => {
  const { name } = useParams()
  const [station, setStation] = useState({ details: [], });
  const [link, setLink] = useState("");
  const { url } = useRouteMatch()
  const user = getUser
  const [admin, setAdmin] = useState(false)
  const staf_token = localStorage.getItem('auth_token') ? user().then((data) => { setAdmin(data.is_staff) }) : null
  const token = localStorage.getItem('auth_token')
  console.log(token)

  // Возможно это байт, так что потом удали

  useEffect(() => {
    fetch(`${URL}/api/hermodoors/${name}/`,
      {
        method: 'GET',
        headers: {
          'authorization': token ? `Token ${token}` : null
        }
      }
    )
      .then(response => response.json())
      .then(data => {
        setStation({ details: data });
      })
      .catch(error => console.error('Error:', error));
  }, []);

  const copyTextToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Текст успешно скопирован в буфер обмена!');
    } catch (err) {
      console.error('Ошибка:', err);
    }
  };

  // const archive_link = () => { for (const link in station.details.archive) 
  // {return <ul onClick={() => copyTextToClipboard(station.details.documentation)} style={{ color: "black" }}><a>{link}</a></ul>}
  // };

  const arr = station.details.archive || [];

  const groupedLinks = {
    tDrive: [],
    pDrive: [],
    others: [],
  };

  arr.forEach((element) => {
    const link = element["archive_link"];
    if (link.startsWith("T:")) {
      groupedLinks.tDrive.push(element);
    } else if (link.startsWith("P:")) {
      groupedLinks.pDrive.push(element);
    } else {
      groupedLinks.others.push(element);
    }
  });

  const renderLinks = (links) => (
      
        links.map((element, index) => (
          <ul key={index}>
            <a href="#" style={{ color: "black", marginRight: "8px" }}>{element["representation_name"]}</a>
            <FaRegCopy
              style={{ cursor: "pointer" }}
              title="Копировать ссылку"
              onClick={() => copyTextToClipboard(element["archive_link"])}
            />
          </ul>
        ))
      
    );

  return (
    <div className={styles["card"]}>
      <ul style={{ color: "black" }}>{station.details.name}</ul>
      <ul style={{ color: "black" }}>{station.details.line}</ul>
      <ul style={{ color: "black" }}>{station.details.lat} {station.details.long}</ul>

      {groupedLinks.tDrive.length > 0 && (
        <div>
          <h3>Ссылки на рабочем сервере</h3>
          {renderLinks(groupedLinks.tDrive)}
        </div>
      )}
      {groupedLinks.pDrive.length > 0 && (
        <div>
          <h3>Ссылки на удаленном сервере</h3>
          {renderLinks(groupedLinks.pDrive)}
        </div>
      )}
      {groupedLinks.others.length > 0 && (
        <div>
          <h3>Другие ссылки</h3>
          {renderLinks(groupedLinks.others)}
        </div>
      )}

    </div>
  )
}

export default HermodoorsCard
