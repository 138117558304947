import React, { useState, useEffect, useRef } from 'react';
import door from '../../images/door.png'
import building from '../../images/building.png'
import { URL } from "../../utils/constants";
import ymaps from 'ymaps';
import {
  YMaps,
  Map,
  Placemark,
  Polyline,
  SearchControl,
  ObjectManager,
  RulerControl,
  Polygon,
  TypeSelector,
} from '@pbe/react-yandex-maps';
import { getDistance } from "geolib";
import DropdownMenu from './dropdown';


const MapPage = (userState) => {
  const [coordinates, setCoordinates] = useState({ details: [], });
  const [linecoordinates, setLinecoordinates] = useState({ details: [], });
  const [hermodoorcoord, setHermodoorcoord] = useState({ details: [], });
  const [buildingscoord, setBuildingscoord] = useState({ details: [], });
  const [crossway, setCrossway] = useState({ details: [], });
  const [wayoutscoord, setWayoutscoord] = useState({ details: [], });
  const [depocoord, setDepocoord] = useState({ details: [], });
  const [metromostcoord, setMetromostcoord] = useState({ details: [], });
  const [activeStationId, setActiveStationId] = useState(null);
  const polygonsRef = useRef([]); // Массив для хранения ссылок на Polygon
  const linesRef = useRef([]);
  const mapRef = useRef(null);
  const token = localStorage.getItem('auth_token')
  const [visible, setVisible] = useState([])
  const [zoom, setZoom] = useState(11);

  const [activeIds, setActiveIds] = useState({
    activeStations: [],
    activeLines: [],
    activeDiameters: []
  });

  const metroLineColors = {
    "Сокольническая": "EF161E",
    "Замоскворецкая": "2DBE2C",
    "Арбатско-Покровская": "0078BE",
    "Филевская": "00BFFF",
    "Кольцевая": "8D5B2D",
    "Калужско-Рижская": "ED9121",
    "Таганско-Краснопресненская": "800080",
    "Калининская": "FFD702",
    "Солнцевская": "FFD702",
    "Серпуховско-Тимирязевская": "999999",
    "Люблинско-Дмитровская": "99CC00",
    "Большая кольцевая": "82C0C0",
    "Бутовская": "A1B3D4",
    "Московское центральное кольцо": "FFFFFF",
    "Троицкая": "03795F",
    "Рублево-Архангельская": "27303F",
    "Некрасовская": "DE64A1",
    "БКЛ": "82C0C0",
    "Большая кольцевая линия метро": "82C0C0",
    "D1": "ef9548",
    "D2": "f754e1",
    "D3": "ffefd5",
    "D4": "3eb489",
  };

  const metroLineNumbers = {
    "Сокольническая": 1,
    "Замоскворецкая": 2,
    "Арбатско-Покровская": 3,
    "Филевская": 4,
    "Кольцевая": 5,
    "Калужско-Рижская": 6,
    "Таганско-Краснопресненская": 7,
    "Калининская": 8,
    "Серпуховско-Тимирязевская": 9,
    "Люблинско-Дмитровская": 10,
    "БКЛ": 11,
    "Бутовская": 12,
    "Московский монорельс": 13,
    "Московское центральное кольцо": 14,
    "Некрасовская": 15,
    "Троицкая": 16,
    "Рублёво-Архангельская": 17,
    "Бирюлёвская": 18,
    "Власьевская": 19,
    "D1": "D1",
    "D2": "D2",
    "D3": "D3",
    "D4": "D4"
  }

  const fetchData = (endpoint, setter) => {
    const headers = token ? { 'Authorization': `Token ${token}` } : {};

    fetch(`${URL}/api/${endpoint}/`, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        return response.json();
      })
      .then(data => {
        setter({ details: data });
      })
      .catch(error => console.error(`Error fetching ${endpoint}:`, error));
  };

  // Используем useEffect для вызова fetchData для каждого запроса
  useEffect(() => {
    fetchData('crossway', setCrossway);
  }, []);

  useEffect(() => {
    fetchData('stations', setCoordinates);
  }, []);

  useEffect(() => {
    fetchData('linesections', setLinecoordinates);
  }, []);

  useEffect(() => {
    fetchData('hermodoors', setHermodoorcoord);
  }, []);

  useEffect(() => {
    fetchData('buildings', setBuildingscoord);
  }, []);

  useEffect(() => {
    fetchData('wayouts', setWayoutscoord);
  }, []);

  useEffect(() => {
    fetchData('metrobridges', setMetromostcoord);
  }, []);

  useEffect(() => {
    fetchData('depos', setDepocoord);
  }, []);

  useEffect(() => {
    if (!mapRef.current) return; // Ждём, пока карта появится

    const mapInstance = mapRef.current; // Сохраняем объект карты
    const handleBoundsChange = () => {
      const newZoom = mapInstance._zoom; // Получаем текущий зум
      console.log("Zoom изменился:", newZoom);
      setZoom(newZoom);
    };

    mapInstance.events.add("boundschange", handleBoundsChange);

    // Очистка обработчика при размонтировании
    return () => {
      mapInstance.events.remove("boundschange", handleBoundsChange);
    };
  }, [mapRef.current]);

  const handleStationClick = (stationId) => {
    toggleActiveStation(stationId);
  };

  const handleLineClick = (lineId) => {
    toggleActiveLine(lineId);
  };

  function handleLineClickk(lineId) {

  };

  const toggleActiveStation = (stationId) => {
    setActiveIds((prev) => {
      const isActive = prev.activeStations.includes(stationId);
      return {
        ...prev,
        activeStations: isActive
          ? prev.activeStations.filter((id) => id !== stationId)
          : [...prev.activeStations, stationId],
      };
    });
  };

  const toggleActiveLine = (lineIds) => {
    setActiveIds((prev) => {
      const updatedActiveLines = [...prev.activeLines];

      lineIds.forEach((lineId) => {
        const isActive = updatedActiveLines.includes(lineId);
        if (isActive) {
          // Если ID активен, удаляем его
          const index = updatedActiveLines.indexOf(lineId);
          if (index !== -1) {
            updatedActiveLines.splice(index, 1);
          }
        } else {
          // Если ID не активен, добавляем его
          updatedActiveLines.push(lineId);
        }
      });

      return {
        ...prev,
        activeLines: updatedActiveLines,
      };
    });
  };

  // Обработка изменения состояния activeStationId
  useEffect(() => {
    // Открываем балун у новой активной станции, если она задана
    if (activeStationId !== null && polygonsRef.current[activeStationId]) {
      polygonsRef.current[activeStationId].balloon.open();
    }
  }, [activeStationId]);

  // Обработчик закрытия балуна
  const handleBalloonClose = () => {
    setActiveIds({
      activeStations: [],
      activeLines: [],
    });
  };

  // Отрисовка станций
  const station_sq = coordinates.details.map((element, index) => {
    const isActive = activeStationId === element.id; // Проверяем, является ли станция активной
    const isDimmed = activeIds.activeStations.length > 0 && !activeIds.activeStations.includes(element.id); // Если есть активная станция, остальные затемняются

    return (
      <Polygon
        key={index}
        geometry={Array.isArray(element.station_coordinate) && element.station_coordinate[0].length > 2
          ? element.station_coordinate
          : [element.station_coordinate]}
        options={{
          fillColor: "#5e5b5b",
          strokeColor: "#2b2929",
          opacity:
            visible.length === 0 ||
              (visible.includes('stations') && !visible.includes('archive')) ||
              (visible.includes('stations') && visible.includes('archive') && element.archive.length !== 0) ||
              (visible.includes('archive') && element.archive.length !== 0 && visible.length === 1)
              ? 1.0
              : 0.1,
          strokeWidth: 5,
          zIndex: 6
        }}
        onBalloonClose={handleBalloonClose}
        onClick={() => handlePointClick(element)}
        instanceRef={(ref) => {
          if (ref) polygonsRef.current[element.id] = ref; // Сохраняем ссылку на Polygon
        }}
        properties={{
          stationId: element.id, // Добавляем ID станции для поиска
          balloonContentHeader: `<a href='stations/${element.id}'>${element.name}</a>`,
          balloonContentBody: `${element.line}`,
          balloonContentFooter: 'Подпись',
        }}
      />
    );
  });

  const depo = depocoord.details.map((element, index) => {
    const isActive = activeStationId === element.id; // Проверяем, является ли станция активной
    const isDimmed = activeIds.activeStations.length > 0 || activeIds.activeLines.length > 0; // Если есть активная станция, остальные затемняются

    return (
      <Polygon
        key={index}
        geometry={[element.coordinate]}
        options={{
          fillColor: "#5e5b5b",
          strokeColor: element.archive?.length > 0 ? "#ffffff" : "#2b2929",
          opacity:
            visible.length === 0 ||
              (visible.includes('stations') && !visible.includes('archive')) ||
              (visible.includes('stations') && visible.includes('archive') && element.archive.length !== 0) ||
              (visible.includes('archive') && element.archive.length !== 0 && visible.length === 1)
              ? 1.0
              : 0.1,
          strokeWidth: 5,
          zIndex: 4
        }}

        instanceRef={(ref) => {
          if (ref) polygonsRef.current[element.id] = ref; // Сохраняем ссылку на Polygon
        }}
        properties={{
          stationId: element.id, // Добавляем ID станции для поиска
          balloonContentHeader: `${element.name}`,
        }}
      />
    );
  });

  const metrobridge = metromostcoord.details.map((element, index) => {
    const isActive = activeStationId === element.id; // Проверяем, является ли станция активной
    const isDimmed = activeIds.activeStations.length > 0 || activeIds.activeLines.length > 0; // Если есть активная станция, остальные затемняются

    return (
      <Polygon
        key={index}
        geometry={[element.coordinate]}
        options={{
          fillColor: "#5e5b5b",
          strokeColor: element.archive?.length > 0 ? "#ffffff" : "#2b2929",
          opacity:
            visible.length === 0 ||
              (visible.includes('stations') && !visible.includes('archive')) ||
              (visible.includes('stations') && visible.includes('archive') && element.archive.length !== 0) ||
              (visible.includes('archive') && element.archive.length !== 0 && visible.length === 1)
              ? 1.0
              : 0.1,
          strokeWidth: 5,
          zIndex: 3
        }}

        instanceRef={(ref) => {
          if (ref) polygonsRef.current[element.id] = ref; // Сохраняем ссылку на Polygon
        }}
        properties={{
          stationId: element.id, // Добавляем ID станции для поиска
          balloonContentHeader: `${element.name}`,
        }}
      />
    );
  });

  function handlePointClick(point) {
    // Затемняем все полигоны, кроме выбранного
    Object.keys(polygonsRef.current).forEach((id) => {
      const polygon = polygonsRef.current[id];
      if (polygon) {
        polygon.options.set('opacity', id === point.id ? 1.0 : 0.4);
      }
    });
    // Находим соответствующий полигон для станции
    const targetPolygon = polygonsRef.current[point.id];
    if (targetPolygon) {
      handleBalloonClose()
      // Центрируем карту и открываем балун у соответствующего полигона
      targetPolygon.balloon.open();
      handleStationClick(point.id);
    } else {
      console.error('Не удалось найти полигон для точки:', point.id);
    }
  }

  const linesection_sq = linecoordinates.details.map((element, index) => {
    const isDimmed = activeIds.activeLines.length > 0 && !activeIds.activeLines.includes(element.id);


    return element.isPolygone ? (
      <Polygon
        key={index}
        geometry={[element.coordinate]}
        options={{
          fillColor: metroLineColors[element.line],
          strokeColor: metroLineColors[element.line],
          opacity:
            visible.length === 0 ||
              (visible.includes('lines') && !visible.includes('archive')) ||
              (visible.includes('lines') && visible.includes('archive') && element.archive.length !== 0) ||
              (visible.includes('archive') && element.archive.length !== 0 && visible.length === 1)
              ? 0.8
              : 0.1,
          strokeWidth: 8,
          zIndex: 1
        }}
        instanceRef={(ref) => {
          if (ref) linesRef.current[element.id] = ref; // Сохраняем ссылку на линию
        }}
        properties={{
          balloonContentHeader: `<a href='linesections/${element.id}'>${element.name}</a>`,
          balloonContentBody: `${element.line}`,
          balloonContentFooter: "Подпись",
        }}
      />
    ) : (
      <Polyline
        key={index}
        geometry={element.coordinate}
        options={{
          strokeColor: metroLineColors[element.line],
          opacity:
            visible.length === 0 ||
              (visible.includes('lines') && !visible.includes('archive')) ||
              (visible.includes('lines') && visible.includes('archive') && element.archive.length !== 0) ||
              (visible.includes('archive') && element.archive.length !== 0 && visible.length === 1)
              ? 0.8
              : 0.1,
          strokeWidth: 8,
          zIndex: 1
        }}
        instanceRef={(ref) => {
          if (ref) linesRef.current[element.id] = ref; // Сохраняем ссылку на линию
        }}
        properties={{
          balloonContentHeader: `<a href='linesections/${element.id}'>${element.name}</a>`,
          balloonContentBody: `${element.line}`,
          balloonContentFooter: "Подпись",
        }}
      />
    );
  });

  const wayouts = wayoutscoord.details.map((element, index) => {
    const isActive = activeStationId === element.id; // Проверка, активная ли эта станция
    const isDimmed = activeIds.activeStations.length > 0 || activeIds.activeLines.length > 0;

    return element.isPolygone ? <Polygon
      key={index}
      geometry={[element.coordinate]}
      options={{
        // fillColor: metroLineColors[element.line],
        strokeColor: '231F20',
        opacity:
          visible.length === 0 ||
            (visible.includes('lines') && !visible.includes('archive'))
            ? 0.8
            : 0.1,
        strokeWidth: 8,
        zIndex: 2
      }}
      onClick={() => handlePointClick(element)}
      onBalloonClose={handleBalloonClose}
      // replace
      properties={{
        balloonContentHeader: 'Съезд'
      }}
    /> : <Polyline
      key={index}
      geometry={element.coordinate}
      options={{
        strokeColor: '231F20',
        opacity:
          visible.length === 0 ||
            (visible.includes('lines') && !visible.includes('archive'))
            ? 0.8
            : 0.1,
        strokeWidth: 8,
        strokeOpacity: isDimmed ? 0.1 : 1,
      }}
      onClick={() => handlePointClick(element)}
      onBalloonClose={handleBalloonClose}
      properties={{
        balloonContentHeader: 'Съезд'
      }}
    />
  });

  const crossways = crossway.details.map((element, index) => {
    const isDimmed = activeIds.activeStations.length > 0 || activeIds.activeLines.length > 0;

    return <Polygon
      key={index}
      geometry={[element.crossway_coordinate]}
      options={{
        fillColor: "#808080",
        strokeColor: "#000000",
        opacity:
          visible.length === 0 ||
            (visible.includes('stations') && !visible.includes('archive'))
            ? 0.8
            : 0.1,
        strokeWidth: 3,
        zIndex: 5
      }}
      properties={{
        balloonContentHeader: element.id,
        //iconContent: `Content`,
      }}
    // replace

    />;
  });

  const hermodors = hermodoorcoord.details.map((element, index) => {
    return visible.length === 0 ||
      (visible.includes('hermodoors') && !visible.includes('archive')) ||
      (visible.includes('hermodoors') && visible.includes('archive') && element.archive.length !== 0) ||
      (visible.includes('archive') && element.archive.length !== 0 && visible.length === 1) ?
      <Placemark
        key={index}
        geometry={[element.long, element.lat]}
        properties={{
          balloonContentHeader: `<a href='hermodoors/${element.id}'>${element.name}</a>`,
          balloonContentBody: element.line,
          balloonContentFooter: element.documentation,
          balloonContentHint: element.name,

          //iconContent: `Content`,
        }}
        options={
          {

            draggable: false,
            iconLayout: 'default#image',
            iconImageSize: [20, 20],
            iconImageHref: door,
            iconImageOffset: [-10, -20],

          }
        }
      /> : null;
  });

  const buildings = buildingscoord.details.map((element, index) => {
    return visible.length === 0 ||
      (visible.includes('buildings') && !visible.includes('archive')) ||
      (visible.includes('buildings') && visible.includes('archive') && element.archive.length !== 0) ||
      (visible.includes('archive') && element.archive.length !== 0 && visible.length === 1) ? <Placemark
      key={index}
      geometry={[element.long, element.lat]}
      properties={{
        balloonContentHeader: `<a href='buildings/${element.id}'>${element.name}</a>`,
        balloonContentBody: element.line,
        balloonContentFooter: element.documentation,
        balloonContentHint: element.name,

        //iconContent: `Content`,
      }}
      options={
        {
          draggable: false,
          iconLayout: 'default#image',
          iconImageSize: zoom ? [zoom * 2, zoom * 2] : [35, 35],
          iconImageHref: building,
          iconImageOffset: zoom ? [-zoom * 2 / 3.5, -zoom * 2 / 1.75] : [-10, -20],
        }
      }
    /> : null;
  });

  const modules = ["layout.ImageWithContent", "GeoObjectCollection", "geoObject.addon.balloon", "Placemark"];

  function CustomSearchProvider(points) {
    this.points = points;
  }

  CustomSearchProvider.prototype.geocode = function (request) {
    if (ymaps.current.suggestView) {
      ymaps.current.suggestView.options.set('results', 0);
    }
    if (ymaps.current.suggestView) {
      ymaps.current.suggestView.state.set('requestInProgress', false);
    }
    if (ymaps.current.suggestView) {
      ymaps.current.suggestView.events.add('requestsuccess', function (e) {
        if (e.get('found') === 0) {
          // Показываем сообщение 'Ничего'
          ymaps.current.suggestView.showNoResultsMessage();
        }
      });
    }

    const deferred = ymaps.current.vow.defer();

    // Находим станции, соответствующие запросу
    const foundPoints = this.points.filter((point) =>
      point.name.toLowerCase().includes(request.toLowerCase())
    );

    if (foundPoints.length === 1) {
      const point = foundPoints[0];

      handlePointSelection(foundPoints[0]);
      deferred.resolve({
        geoObjects: null,
        metaData: {
          geocoder: {
            request,
            found: 1,
          },
        },
      });
    } else if (foundPoints.length > 1) {
      // Если найдено несколько станций, открываем всплывающее окно для выбора нужной станции
      openPopupSelection(foundPoints, (selectedPoint) => {
        handlePointSelection(selectedPoint);
        deferred.resolve({
          geoObjects: new ymaps.current.GeoObjectCollection(),
          metaData: {
            geocoder: {
              request,
              found: foundPoints.length,
            },
          },
        });
      });
    } else {
      console.error("Станция не найдена.");
      deferred.resolve({
        geoObjects: new ymaps.current.GeoObjectCollection(),
        metaData: {
          geocoder: {
            request,
            found: 0,
          },
        },
      });
    }

    return deferred.promise();
  };

  function handlePointSelection(point) {
    // Затемняем все полигоны, кроме выбранного
    Object.keys(polygonsRef.current).forEach((id) => {
      const polygon = polygonsRef.current[id];
      if (polygon) {
        polygon.options.set('opacity', id === point.id ? 1.0 : 0.4);
      }
    });
    // Находим соответствующий полигон для станции
    const targetPolygon = polygonsRef.current[point.id];
    if (targetPolygon) {
      // Центрируем карту и открываем балун у соответствующего полигона
      mapRef.current.setCenter(targetPolygon.geometry.getBounds()[0], 16);
      targetPolygon.events.fire('click');
    } else {
      console.error('Не удалось найти полигон для точки:', point.id);
    }
  }

  function normalizeLineName(line) {
    return line.replace(/ё/g, 'е');
  }

  function openPopupSelection(points, onSelect) {
    // Создаем всплывающее окно для выбора одной из найденных станций
    const modal = document.createElement('div');
    modal.classList.add('selection-modal');
    modal.innerHTML = `
        <div class="modal-overlay">
            <div class="modal-content">
                <h3>Выберите станцию</h3>
                <ul class="station-list">
                    ${points.map((point, index) => {
      const lineName = normalizeLineName(point.line);
      const color = metroLineColors[lineName] ? `#${metroLineColors[lineName]}` : '#000000';
      return `<li data-index="${index}"><span class="line-color" style="background-color: ${color};"></span> ${point.name}</li>`;
    }).join('')}
                </ul>
                <button class="close-modal">Закрыть</button>
            </div>
        </div>
    `;
    document.body.appendChild(modal);

    // Добавляем обработчик выбора станции
    modal.querySelectorAll('.station-list li').forEach((item) => {
      item.addEventListener('click', () => {
        const selectedIndex = item.getAttribute('data-index');
        const selectedPoint = points[selectedIndex];
        onSelect(selectedPoint);
        closeModal(modal);
      });
    });

    // Добавляем обработчик закрытия модального окна
    modal.querySelector('.close-modal').addEventListener('click', () => {
      closeModal(modal);
    });
  }

  function closeModal(modal) {
    document.body.removeChild(modal);
  }

  // Добавляем стили для модального окна
  const style = document.createElement('style');
  style.innerHTML = `
    .selection-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        opacity: 1 !important;
        filter: none !important;
    }
    .modal-overlay {
        background: white;
        padding: 20px;
        border-radius: 8px;
        width: 300px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        opacity: 1 !important;
        filter: none !important;
    }
    .modal-content h3 {
        margin-top: 0;
        color: #000 !important; /* Чёрный цвет */
        opacity: 1 !important; /* Полностью непрозрачный */
        filter: none !important; /* Убираем возможные фильтры */
        mix-blend-mode: normal !important; /* Исключаем смешивание цветов */
    }
    .station-list {
        list-style-type: none;
        padding: 0;
    }
    .station-list li {
        cursor: pointer;
        padding: 5px;
        border-bottom: 1px solid #ccc;
        display: flex;
        color: #000;
        align-items: center;
        mix-blend-mode: normal !important;
    }
    .station-list li:hover {
        background-color: #f0f0f0;
    }
    .line-color {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 8px;
    }
    .close-modal {
        margin-top: 20px;
    }
`;
  document.head.appendChild(style);

  const [newCoords, setNewCoords] = useState(null);
  const [closestCoords, setClosestCoords] = useState(null);

  const API_KEY = "a9574d29-a9a4-4ce6-b3bf-99919499ad18";

  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        if (value) {
          const res = await fetch(
            `https://geocode-maps.yandex.ru/1.x/?apikey=${API_KEY}&format=json&geocode=${value}`
          );
          const data = await res.json();
          const collection = data.response.GeoObjectCollection.featureMember.map(
            (item) => item.GeoObject
          );
          setOptions(() => collection);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [value]);

  const [nearestLines, setNearestLines] = useState([]);

  function calculateDistances(coords) {

    const userLat = parseFloat(coords[0]);
    const userLon = parseFloat(coords[1]);

    // Радиус для фильтрации (5 км)
    const radiusKm = 5;
    const kmToLat = radiusKm / 111; // Приблизительное преобразование километров в градусы широты
    const kmToLon = radiusKm / (111 * Math.cos((userLat * Math.PI) / 180)); // Градусы долготы зависят от широты

    // Определяем квадратную область
    const latMin = userLat - kmToLat;
    const latMax = userLat + kmToLat;
    const lonMin = userLon - kmToLon;
    const lonMax = userLon + kmToLon;

    const results = linecoordinates.details.map((line) => {
      let minDistance = Infinity;
      let closest_coords = null

      line.coordinate.forEach((point) => {
        const [pointLat, pointLon] = point;

        // Грубая фильтрация по квадратной области
        if (
          pointLat >= latMin &&
          pointLat <= latMax &&
          pointLon >= lonMin &&
          pointLon <= lonMax
        ) {
          // Рассчитываем точное расстояние для точек внутри квадрата
          const distance = getDistance(
            { latitude: userLat, longitude: userLon },
            { latitude: pointLat, longitude: pointLon }
          );

          if (distance < minDistance) {
            minDistance = distance;
            closest_coords = [pointLat, pointLon]
          }
        }
      });

      return {
        line_id: line.id,
        name: line.name, // Предполагается, что сервер возвращает название линии метро
        min_distance: minDistance / 1000, // Конвертируем метры в километры
        closest_coords: closest_coords,
        line: line.line
      };
    });

    // Фильтруем линии метро в пределах 5 км
    const nearbyLines = results.filter((line) => line.min_distance <= radiusKm);

    const uniqueNearbyLines = Object.values(
      nearbyLines.reduce((acc, line) => {
        if (!acc[line.line] || acc[line.line].min_distance > line.min_distance) {
          acc[line.line] = line;
        }
        return acc;
      }, {})
    );
    setNearestLines(uniqueNearbyLines);
  };

  return (

    <YMaps query={{
      apikey: '520ce6aa-6c0f-4297-9f4a-b0e939f299d1',
      mode: 'debug', load: "Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon,control.SearchControl",
    }}>

      <DropdownMenu
        value={value}
        setValue={setValue}
        options={options} // Передайте реальные данные
        setNewCoords={setNewCoords}
        mapRef={mapRef}
        linecoordinates={linecoordinates}
        coordinates={coordinates}
        toggleActiveStation={toggleActiveStation}
        toggleActiveLine={toggleActiveLine}
        activeIds={activeIds}
        calculateDistances={calculateDistances}
        newCoords={newCoords}
        setClosestCoords={setClosestCoords}
        metroLineColors={metroLineColors}
        setActiveIds={setActiveIds}
        setVisible={setVisible}
        visible={visible}
        userState={userState}
        metroLineNumbers={metroLineNumbers}
      />

      <Map
        width='100vw'
        height='90vh'
        defaultState={{ center: [55.74, 37.62], zoom: 11, type: 'yandex#map', theme: "islands#dark" }}
        modules={modules}
        options={{
          yandexMapDisablePoiInteractivity: true,
          yandexMapAutoDark: true,
          suppressMapOpenBlock: true,
          suppressObsoleteBrowserNotifier: false,
        }}
        instanceRef={(ref) => {
          mapRef.current = ref; // Сохраняем ссылку на объект карты
        }}
        onLoad={(api) => {
          ymaps.current = api;
        }}
      >
        {newCoords && <Placemark
          geometry={newCoords}
          properties={{
            balloonContentBody: nearestLines.sort((a, b) => a.min_distance - b.min_distance)
              .map(
                (line, index) => `
          <div>
            ${index + 1}. Название линии:
            <span 
                style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: #${metroLineColors[line.line]}; margin-right: 5px;">
              </span>
            <a data-id="${line.line_id}" data-coord="${line.closest_coords}" class="line-link">
              ${line.name}
            </a>
            - Расстояние: ${line.min_distance.toFixed(2)} км
          </div>
        `
              )
              .join(""),
          }}
          options={{
            draggable: false,
            iconLayout: "default#image",
          }}
          instanceRef={(ref) => {
            if (ref) {
              ref.events.add("balloonopen", () => {
                document.querySelectorAll(".line-link").forEach((link) => {
                  link.addEventListener("click", (event) => {
                    event.preventDefault();
                    // const lineId = event.target.dataset.id;
                    const coordinates = (event.target.dataset.coord).split(',').map(Number);
                    setClosestCoords(coordinates);
                    // handleLineClickk(lineId); // Передаем только lineId
                  });
                });
              });
            }
          }}
        />}

        {(closestCoords && newCoords) && <Polyline
          geometry={[newCoords, closestCoords]}
          options={{
            strokeColor: '8D5B2D',
            opacity: 0.8,
            strokeWidth: 8,
          }}
          properties={{
            balloonContentHeader: `dfg`,
            balloonContentBody: `gfd`,
            balloonContentFooter: "Подпись",
          }}
        />}

        <TypeSelector options={{ panoramasItemMode: 'off' }} />

        <RulerControl options={{
          position: {
            top: '10px',
            left: '14vw'
          }
        }} />

        <SearchControl
          options={{
            float: "left",
            provider: "yandex#search",
          }}
        />

        <SearchControl
          options={{
            float: "right",
            noPlacemark: true,
            provider: new CustomSearchProvider(coordinates.details),
          }}
        />

        <ObjectManager
          options={{
            clusterize: false,
            gridSize: 150
          }}
          features={null}
          objects={{
            openBalloonOnClick: true,
            preset: "islands#greenDotIcon",
          }}
          modules={[
            "objectManager.addon.objectsBalloon",
            "objectManager.addon.objectsHint",
          ]}
        />

        {linesection_sq}
        {wayouts}
        {crossways}
        {depo}
        {metrobridge}
        {station_sq}
        {hermodors}
        {buildings}

      </Map>
    </YMaps>
  );
}

export { MapPage }

// Здесь YMaps