import React, { useState } from 'react';

const DropdownMenu = ({ metroLineNumbers, value, setValue, options, setNewCoords, mapRef, linecoordinates, coordinates, toggleActiveStation, toggleActiveLine, activeIds, calculateDistances, setClosestCoords, metroLineColors, setActiveIds, setVisible, visible, userState }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
    const [selectedLine, setSelectedLine] = useState('Выберите линию');
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const [selectedStation, setSelectedStation] = useState('Выберите станцию');
    const [selectedLines, setSelectedLines] = useState([]);
    const [isDiametersMenuOpen, setIsDiametersMenuOpen] = useState(false);
    const [selectedDiameters, setSelectedDiameters] = useState([]);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const toggleSubMenu = () => setSubMenuOpen(!subMenuOpen);
    const toggleFilterMenu = () => setIsFilterMenuOpen(!isFilterMenuOpen);
    const toggleVisibility = (item) => {
        handleClearLines()
        setVisible((prev) =>
            prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
        );
    };
    const toggleDiametersMenu = () => setIsDiametersMenuOpen(!isDiametersMenuOpen);

    const handleDiameterSelect = (diameter) => {
        const isSelected = selectedDiameters.includes(diameter);
        if (isSelected) {
            setSelectedDiameters(selectedDiameters.filter(d => d !== diameter));
        } else {
            setSelectedDiameters([...selectedDiameters, diameter]);
        }
    };

    const handleClearLines = () => {
        setActiveIds(prev => ({ ...prev, activeLines: [], activeStations: [] }))
        setSelectedLines([])
    }

    const handleLineSelect = (item) => {
        setVisible([])
        setSelectedLine(item);
        toggleActiveLine(groupedLines[item])
        // setIsMenuOpen(false); // Закрыть подменю
        setSelectedStation('Выберите станцию'); // Сброс подкатегории
        const isSelected = selectedLines.includes(item);
        if (isSelected) {
            setSelectedLines(selectedLines.filter(line => line !== item));

        } else {
            setSelectedLines([...selectedLines, item]);

        }
    };

    // const metroLineNumbers = {
        // "Сокольническая": 1,
        // "Замоскворецкая": 2,
        // "Арбатско-Покровская": 3,
        // "Филевская": 4,
        // "Кольцевая": 5,
        // "Калужско-Рижская": 6,
        // "Таганско-Краснопресненская": 7,
        // "Калининская": 8,
        // "Серпуховско-Тимирязевская": 9,
        // "Люблинско-Дмитровская": 10,
        // "БКЛ": 11,
        // "Бутовская": 12,
        // "Московский монорельс": 13,
        // "Московское центральное кольцо": 14,
        // "Некрасовская": 15,
        // "Троицкая": 16,
        // "Рублёво-Архангельская": 17,
        // "Бирюлёвская": 18,
        // "Власьевская": 19,
        // "D1": "D1",
        // "D2": "D2",
        // "D3": "D3",
        // "D4": "D4"
    // }

    const metroStationOrder = {
        "Сокольническая": [
            "Бульвар Рокоссовского",
            "Черкизовская",
            "Преображенская площадь",
            "Сокольники",
            "Красносельская",
            "Комсомольская",
            "Красные Ворота",
            "Чистые пруды",
            "Лубянка",
            "Охотный Ряд",
            "Библиотека имени Ленина",
            "Кропоткинская",
            "Парк культуры",
            "Фрунзенская",
            "Спортивная",
            "Воробьёвы горы",
            "Университет",
            "Проспект Вернадского",
            "Юго-Западная",
            "Тропарёво",
            "Румянцево",
            "Саларьево",
            "Филатов Луг",
            "Прокшино",
            "Ольховая",
            "Новомосковская",
            "Потапово",
        ],
        "Замоскворецкая": [
            "Ховрино",
            "Беломорская",
            "Речной вокзал",
            "Водный стадион",
            "Войковская",
            "Сокол",
            "Аэропорт",
            "Динамо",
            "Белорусская",
            "Маяковская",
            "Тверская",
            "Театральная",
            "Новокузнецкая",
            "Павелецкая",
            "Автозаводская",
            "Технопарк",
            "Коломенская",
            "Каширская",
            "Кантемировская",
            "Царицыно",
            "Орехово",
            "Домодедовская",
            "Красногвардейская",
            "Алма-Атинская",
        ],
        "Арбатско-Покровская": [
            "Пятницкое шоссе",
            "Митино",
            "Волоколамская",
            "Мякинино",
            "Строгино",
            "Крылатское",
            "Молодёжная",
            "Кунцевская",
            "Славянский бульвар",
            "Парк Победы",
            "Киевская",
            "Смоленская",
            "Арбатская",
            "Площадь Революции",
            "Курская",
            "Бауманская",
            "Электрозаводская",
            "Семёновская",
            "Партизанская",
            "Измайловская",
            "Первомайская",
            "Щёлковская",
        ],
        "Филевская": [
            "Кунцевская",
            "Пионерская",
            "Филёвский парк",
            "Багратионовская",
            "Фили",
            "Кутузовская",
            "Студенческая",
            "Киевская",
            "Смоленская",
            "Арбатская",
            "Александровский сад",
            "Деловой центр",
            "Москва-Сити",
        ],
        "Кольцевая": [
            "Курская",
            "Таганская",
            "Павелецкая",
            "Добрынинская",
            "Октябрьская",
            "Парк культуры",
            "Киевская",
            "Краснопресненская",
            "Белорусская",
            "Новослободская",
            "Проспект Мира",
            "Комсомольская",
        ],
        "Калужско-Рижская": [
            "Медведково",
            "Бабушкинская",
            "Свиблово",
            "Ботанический сад",
            "ВДНХ",
            "Алексеевская",
            "Рижская",
            "Проспект Мира",
            "Сухаревская",
            "Тургеневская",
            "Китай-город",
            "Третьяковская",
            "Октябрьская",
            "Шаболовская",
            "Ленинский проспект",
            "Академическая",
            "Профсоюзная",
            "Новые Черёмушки",
            "Калужская",
            "Беляево",
            "Коньково",
            "Тёплый Стан",
            "Ясенево",
            "Новоясеневская",
        ],
        "Таганско-Краснопресненская": [
            "Планерная",
            "Сходненская",
            "Тушинская",
            "Спартак",
            "Щукинская",
            "Октябрьское Поле",
            "Полежаевская",
            "Беговая",
            "Улица 1905 года",
            "Баррикадная",
            "Пушкинская",
            "Кузнецкий Мост",
            "Китай-город",
            "Таганская",
            "Пролетарская",
            "Волгоградский проспект",
            "Текстильщики",
            "Кузьминки",
            "Рязанский проспект",
            "Выхино",
            "Лермонтовский проспект",
            "Жулебино",
            "Котельники",
        ],
        "Солнцевская": [
            "Новокосино",
            "Новогиреево",
            "Перово",
            "Шоссе Энтузиастов",
            "Авиамоторная",
            "Площадь Ильича",
            "Марксистская",
            "Третьяковская",
            "Деловой центр",
            "Парк Победы",
            "Минская",
            "Ломоносовский проспект",
            "Раменки",
            "Мичуринский проспект",
            "Озёрная",
            "Говорово",
            "Солнцево",
            "Боровское шоссе",
            "Новопеределкино",
            "Рассказовка",
            "Пыхтино",
            "Аэропорт Внуково",
        ],
        "Серпуховско-Тимирязевская": [
            "Алтуфьево",
            "Бибирево",
            "Отрадное",
            "Владыкино",
            "Петровско-Разумовская",
            "Тимирязевская",
            "Дмитровская",
            "Савёловская",
            "Менделеевская",
            "Цветной бульвар",
            "Чеховская",
            "Боровицкая",
            "Полянка",
            "Серпуховская",
            "Тульская",
            "Нагатинская",
            "Нагорная",
            "Нахимовский проспект",
            "Севастопольская",
            "Чертановская",
            "Южная",
            "Пражская",
            "Улица Академика Янгеля",
            "Аннино",
            "Бульвар Дмитрия Донского",
        ],
        "Люблинско-Дмитровская": [
            "Физтех",
            "Лианозово",
            "Яхромская",
            "Селигерская",
            "Верхние Лихоборы",
            "Окружная",
            "Петровско-Разумовская",
            "Фонвизинская",
            "Бутырская",
            "Марьина Роща",
            "Достоевская",
            "Трубная",
            "Сретенский бульвар",
            "Чкаловская",
            "Римская",
            "Крестьянская Застава",
            "Дубровка",
            "Кожуховская",
            "Печатники",
            "Волжская",
            "Люблино",
            "Братиславская",
            "Марьино",
            "Борисово",
            "Шипиловская",
            "Зябликово",
        ],
        "БКЛ": [
            "Каширская",
            "Варшавская",
            "Каховская",
            "Зюзино",
            "Воронцовская",
            "Новаторская",
            "Проспект Вернадского",
            "Мичуринский проспект",
            "Аминьевская",
            "Давыдково",
            "Кунцевская",
            "Терехово",
            "Мнёвники",
            "Народное Ополчение",
            "Хорошёвская",
            "ЦСКА",
            "Петровский парк",
            "Савёловская",
            "Марьина Роща",
            "Рижская",
            "Сокольники",
            "Электрозаводская",
            "Лефортово",
            "Авиамоторная",
            "Нижегородская",
            "Текстильщики",
            "Печатники",
            "Нагатинский Затон",
            "Кленовый бульвар",
        ],
        "Бутовская": [
            "Битцевский парк",
            "Лесопарковая",
            "Улица Старокачаловская",
            "Улица Скобелевская",
            "Бульвар Адмирала Ушакова",
            "Улица Горчакова",
            "Бунинская Аллея",
        ],
        "Некрасовская": [
            "Нижегородская",
            "Стахановская",
            "Окская",
            "Юго-Восточная",
            "Косино",
            "Улица Дмитриевского",
            "Лухмановская",
            "Некрасовка",
        ],
        "Троицкая": [
            "Новаторская",
            "Университет дружбы народов",
            "Генерала Тюленева",
            "Тютчевская",
            "Корниловская",
            "Коммунарка",
            "Новомосковская",
        ],
    }

    const handleStationSelect = (station) => {
        setVisible([])
        toggleActiveStation(station.id)
    };

    const groupedLines = linecoordinates.details.reduce((acc, line) => {
        if (!acc[line.line]) {
            acc[line.line] = [];
        }
        acc[line.line].push(line.id); // Добавляем id части линии
        return acc;
    }, {});

    const uniqueLines = Object.keys(groupedLines);

    const sortedLines = [...uniqueLines].sort((a, b) => {
        const lineNumberA = metroLineNumbers[a] || Infinity; // Устанавливаем Infinity, если линия отсутствует в объекте
        console.log(a)
        console.log(metroLineNumbers[a])
        const lineNumberB = metroLineNumbers[b] || Infinity;
        return lineNumberA - lineNumberB;
    });

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <input
                type="text"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                list="options-list"
                onInput={(e) => {
                    const newValue = e.currentTarget.value;
                    const obg = options.find(
                        (item) =>
                            newValue.includes(item.name) &&
                            newValue.includes(item.description)
                    );
                    if (obg) {
                        const coords = obg.Point.pos
                            .split(' ')
                            .map((item) => Number(item))
                            .reverse();
                        setNewCoords(coords);

                        mapRef.current.setCenter(coords, 16);
                        calculateDistances(coords);
                    }
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        const obg = options.find(
                            (item) =>
                                value.includes(item.name) &&
                                value.includes(item.description)
                        );
                        if (obg) {
                            const coords = obg.Point.pos
                                .split(' ')
                                .map((item) => Number(item))
                                .reverse();
                            mapRef.current.setCenter(coords, 16);
                        }
                    }
                }}
                style={{
                    width: '30%',
                    padding: '8px',
                    fontSize: '16px',
                    boxSizing: 'border-box',
                }}
            />

            <button
                onClick={() => setValue('') & setNewCoords(null) & setClosestCoords(null)}
                style={{
                    padding: '10px 15px',
                    fontSize: '16px',
                    backgroundColor: '#007BFF',
                    color: '#FFFFFF',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                }}
            >
                ✖
            </button>

            <datalist id="options-list">
                {options.map((item, index) => (
                    <option key={index} value={`${item.name} ${item.description}`} />
                ))}
            </datalist>

            {/* Первое меню */}
            <div style={{ position: 'relative' }}>
                <button
                    onClick={toggleMenu}
                    style={{
                        width: '300px',
                        padding: '10px 15px',
                        fontSize: '16px',
                        backgroundColor: '#007BFF',
                        color: '#FFFFFF',
                        border: 'none',
                        borderRadius: '8px',
                        cursor: 'pointer',
                    }}
                >
                    Выберите линии ▼
                </button>
                {isMenuOpen && (
                    <div
                        style={{
                            width: '300px', // Увеличиваем ширину столбцов
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            backgroundColor: '#FFFFFF',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            borderRadius: '4px',
                            zIndex: 1000,
                        }}
                    >
                        <li
                            style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                gap: '10px',
                                padding: '10px 15px',
                                cursor: 'pointer',
                                fontSize: '16px',
                                color: '#333333',
                                backgroundColor: '#FFFFFF',
                                borderBottom: '1px solid #EEEEEE',
                            }}
                            onClick={handleClearLines}
                        >
                            <div
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '4px',
                                    border: '2px solid #007BFF',
                                    backgroundColor: selectedLines.length == 0 ? '#007BFF' : 'transparent',
                                }}
                            />
                            <span>Сбросить выбор</span>
                        </li>
                        <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                            {sortedLines.map((line, index) => (
                                <li
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'flex-start', // Выравниваем элементы по началу строки
                                        gap: '10px',
                                        padding: '10px 15px',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        color: '#333333',
                                        backgroundColor: '#FFFFFF',
                                        borderBottom: '1px solid #EEEEEE',
                                    }}
                                    onClick={() => handleLineSelect(line)}
                                >
                                    {/* Синий квадратик */}
                                    <div
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            borderRadius: '4px',
                                            border: '2px solid #007BFF',
                                            backgroundColor: selectedLines.includes(line) ? '#007BFF' : 'transparent',
                                        }}
                                    />
                                    <span
                                        style={{
                                            wordWrap: 'break-word', // Переносим длинные слова
                                            overflowWrap: 'break-word', // Переносим длинные строки
                                            textAlign: 'left', // Выравнивание по левому краю
                                            lineHeight: '1.5', // Увеличиваем межстрочный интервал для удобства чтения
                                        }}
                                    >
                                        <span
                                            style={{
                                                display: 'inline-flex', // Используем flex для центрирования текста
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '20px', // Увеличил размер, чтобы текст поместился
                                                height: '20px',
                                                borderRadius: '50%',
                                                backgroundColor: `#${metroLineColors[line]}`,
                                                color: '#FFFFFF', // Белый цвет текста для контраста
                                                fontSize: '15px', // Размер текста
                                                // fontWeight: 'bold', // Жирный шрифт
                                                textAlign: 'center',
                                                lineHeight: '20px', // Выравниваем текст по высоте
                                                marginRight: '5px'
                                            }}
                                        >
                                            
                                            {metroLineNumbers[line]}
                                        </span>

                                        {line}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>

                )}
            </div>

            {/* Второе меню */}
            <div style={{ position: 'relative' }}>
                <button
                    onClick={toggleSubMenu}
                    disabled={selectedLines.length === 0}
                    style={{
                        width: '420px',
                        padding: '10px 15px',
                        fontSize: '16px',
                        backgroundColor: selectedLines.length === 0 ? '#CCCCCC' : '#28A745',
                        color: '#FFFFFF',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: selectedLines.length === 0 ? 'not-allowed' : 'pointer',
                    }}
                >
                    {selectedStation}
                    ▼
                </button>
                {subMenuOpen && selectedLines.length > 0 && (
                    <div
                        style={{
                            width: '400px',
                            overflowX: 'auto', // Горизонтальная прокрутка
                            display: 'flex',
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            backgroundColor: '#FFFFFF',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            borderRadius: '4px',
                            zIndex: 1000,
                            padding: '10px',
                            gap: '10px',
                        }}
                    >

                        {selectedLines.map((line, index) => {
                            const stations = coordinates.details.filter(
                                (station) => station.line === line
                            );

                            return (
                                <div
                                    key={index}
                                    style={{
                                        flex: '0 0 auto', // Убираем перенос
                                        width: '220px', // Фиксированная ширина столбца
                                        padding: '10px',
                                        backgroundColor: '#f9f9f9',
                                        borderRadius: '4px',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    <h4
                                        style={{
                                            margin: '0 0 10px',
                                            color: '#007BFF',
                                            textAlign: 'left', // Гарантируем левое выравнивание заголовка
                                        }}
                                    >
                                        {line}
                                    </h4>
                                    <ul
                                        style={{
                                            listStyle: 'none',
                                            padding: 0,
                                            margin: 0,
                                        }}
                                    >
                                        <li
                                            style={{
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                gap: '10px',
                                                padding: '10px 15px',
                                                cursor: 'pointer',
                                                fontSize: '16px',
                                                color: '#333333',
                                                backgroundColor: '#FFFFFF',
                                                borderBottom: '1px solid #EEEEEE',
                                            }}
                                            onClick={() => setActiveIds(prev => ({ ...prev, activeStations: [] }))}
                                        >
                                            <div
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    borderRadius: '4px',
                                                    border: '2px solid #007BFF',
                                                    backgroundColor: activeIds.activeStations.length == 0 ? '#007BFF' : 'transparent',
                                                }}
                                            />
                                            <span>Сбросить выбор</span>
                                        </li>
                                        {stations
                                            .sort((a, b) => {
                                                // Получаем текущую линию метро
                                                const lineOrder = metroStationOrder[a.line] || [];
                                                // Определяем индекс станции на линии
                                                const indexA = lineOrder.indexOf(a.name);
                                                const indexB = lineOrder.indexOf(b.name);

                                                // Если станция не найдена в порядке, отправляем её в конец
                                                return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
                                            })
                                            .map((station, idx) => (
                                                <li
                                                    key={idx}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'flex-start', // Выравниваем элементы по верхнему краю
                                                        gap: '10px',
                                                        padding: '5px 0',
                                                        cursor: 'pointer',
                                                        fontSize: '14px',
                                                        color: '#333333',
                                                        textAlign: 'left', // Гарантируем левое выравнивание текста
                                                        lineHeight: '1.5', // Увеличиваем интервал между строками
                                                    }}
                                                    onClick={() => handleStationSelect(station)}
                                                >
                                                    {/* Квадратик перед названием станции */}
                                                    <div
                                                        style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            borderRadius: '4px',
                                                            border: '2px solid #007BFF',
                                                            backgroundColor: activeIds.activeStations.includes(station.id)
                                                                ? '#007BFF'
                                                                : 'transparent',
                                                        }}
                                                    />
                                                    <span
                                                        style={{
                                                            display: 'block', // Устанавливаем блочное отображение текста
                                                            wordWrap: 'break-word', // Переносим длинные слова
                                                            overflowWrap: 'break-word', // Переносим строки
                                                            whiteSpace: 'normal', // Разрешаем перенос текста
                                                            textAlign: 'left', //  
                                                        }}
                                                    >
                                                        {station.name}
                                                    </span>
                                                </li>
                                            ))}

                                    </ul>
                                </div>
                            );
                        })}
                    </div>

                )}
            </div>

            {/* Третье меню */}
            <div style={{ position: 'relative' }}>
                <button
                    onClick={toggleFilterMenu}
                    style={{
                        width: '300px',
                        padding: '10px 15px',
                        fontSize: '16px',
                        backgroundColor: '#007BFF',
                        color: '#FFFFFF',
                        border: 'none',
                        borderRadius: '8px',
                        cursor: 'pointer',
                    }}
                >
                    Область видимости ▼
                </button>
                {isFilterMenuOpen && (
                    <div
                        style={{
                            width: '300px', // Увеличиваем ширину столбцов
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            backgroundColor: '#FFFFFF',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            borderRadius: '4px',
                            zIndex: 1000,
                        }}
                    >
                        <li
                            style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                gap: '10px',
                                padding: '10px 15px',
                                cursor: 'pointer',
                                fontSize: '16px',
                                color: '#333333',
                                backgroundColor: '#FFFFFF',
                                borderBottom: '1px solid #EEEEEE',
                            }}
                            onClick={() => { setVisible([]) }}
                        >
                            <div
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '4px',
                                    border: '2px solid #007BFF',
                                    backgroundColor: visible.length == 0 ? '#007BFF' : 'transparent',
                                }}
                            />
                            <span>Сбросить выбор</span>
                        </li>
                        <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                            <li
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-start', // Выравниваем элементы по началу строки
                                    gap: '10px',
                                    padding: '10px 15px',
                                    cursor: 'pointer',
                                    fontSize: '16px',
                                    color: '#333333',
                                    backgroundColor: '#FFFFFF',
                                    borderBottom: '1px solid #EEEEEE',
                                }}
                                onClick={() => toggleVisibility('lines')}
                            >
                                {/* Синий квадратик */}
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '4px',
                                        border: '2px solid #007BFF',
                                        backgroundColor: visible.includes('lines') ? '#007BFF' : 'transparent',
                                    }}
                                />
                                <span
                                    style={{
                                        wordWrap: 'break-word', // Переносим длинные слова
                                        overflowWrap: 'break-word', // Переносим длинные строки
                                        textAlign: 'left', // Выравнивание по левому краю
                                        lineHeight: '1.5', // Увеличиваем межстрочный интервал для удобства чтения
                                    }}
                                >
                                    Линии метро и съезды
                                </span>
                            </li>
                            <li
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-start', // Выравниваем элементы по началу строки
                                    gap: '10px',
                                    padding: '10px 15px',
                                    cursor: 'pointer',
                                    fontSize: '16px',
                                    color: '#333333',
                                    backgroundColor: '#FFFFFF',
                                    borderBottom: '1px solid #EEEEEE',
                                }}
                                onClick={() => toggleVisibility('stations')}
                            >
                                {/* Синий квадратик */}
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '4px',
                                        border: '2px solid #007BFF',
                                        backgroundColor: visible.includes('stations') ? '#007BFF' : 'transparent',
                                    }}
                                />
                                <span
                                    style={{
                                        wordWrap: 'break-word', // Переносим длинные слова
                                        overflowWrap: 'break-word', // Переносим длинные строки
                                        textAlign: 'left', // Выравнивание по левому краю
                                        lineHeight: '1.5', // Увеличиваем межстрочный интервал для удобства чтения
                                    }}
                                >
                                    Станции метро и выходы
                                </span>
                            </li>
                            <li
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-start', // Выравниваем элементы по началу строки
                                    gap: '10px',
                                    padding: '10px 15px',
                                    cursor: 'pointer',
                                    fontSize: '16px',
                                    color: '#333333',
                                    backgroundColor: '#FFFFFF',
                                    borderBottom: '1px solid #EEEEEE',
                                }}
                                onClick={() => toggleVisibility('hermodoors')}
                            >
                                {/* Синий квадратик */}
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '4px',
                                        border: '2px solid #007BFF',
                                        backgroundColor: visible.includes('hermodoors') ? '#007BFF' : 'transparent',
                                    }}
                                />
                                <span
                                    style={{
                                        wordWrap: 'break-word', // Переносим длинные слова
                                        overflowWrap: 'break-word', // Переносим длинные строки
                                        textAlign: 'left', // Выравнивание по левому краю
                                        lineHeight: '1.5', // Увеличиваем межстрочный интервал для удобства чтения
                                    }}
                                >
                                    Гермозатворы
                                </span>
                            </li>
                            {userState.userState && (
                                <li
                                    style={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        gap: '10px',
                                        padding: '10px 15px',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        color: '#333333',
                                        backgroundColor: '#FFFFFF',
                                        borderBottom: '1px solid #EEEEEE',
                                    }}
                                    onClick={() => toggleVisibility('buildings')}
                                >
                                    <div
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            borderRadius: '4px',
                                            border: '2px solid #007BFF',
                                            backgroundColor: visible.includes('buildings') ? '#007BFF' : 'transparent',
                                        }}
                                    />
                                    <span>Здания</span>
                                </li>
                            )}

                            {userState.userState && (
                                <li
                                    style={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        gap: '10px',
                                        padding: '10px 15px',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        color: '#333333',
                                        backgroundColor: '#FFFFFF',
                                        borderBottom: '1px solid #EEEEEE',
                                    }}
                                    onClick={() => toggleVisibility('archive')}
                                >
                                    <div
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            borderRadius: '4px',
                                            border: '2px solid #007BFF',
                                            backgroundColor: visible.includes('archive') ? '#007BFF' : 'transparent',
                                        }}
                                    />
                                    <span>Фильтр 'с документацией'</span>
                                </li>
                            )}

                        </ul>
                    </div>

                )}
            </div>

            <div style={{ position: 'relative' }}>
                <button
                    onClick={toggleDiametersMenu}
                    style={{
                        width: '200px',
                        padding: '10px 15px',
                        fontSize: '16px',
                        backgroundColor: '#007BFF',
                        color: '#FFFFFF',
                        border: 'none',
                        borderRadius: '8px',
                        cursor: 'pointer',
                    }}
                >
                    Диаметры ▼
                </button>
                {isDiametersMenuOpen && (
                    <div
                        style={{
                            width: '200px',
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            backgroundColor: '#FFFFFF',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            borderRadius: '4px',
                            zIndex: 1000,
                        }}
                    >
                        {['D1', 'D2', 'D3', 'D4'].map((diameter, index) => (
                            <li
                                key={index}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    padding: '10px 15px',
                                    cursor: 'pointer',
                                    fontSize: '16px',
                                    color: '#333333',
                                    backgroundColor: '#FFFFFF',
                                    borderBottom: '1px solid #EEEEEE',
                                }}
                                onClick={() => handleDiameterSelect(diameter)}
                            >
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '4px',
                                        border: '2px solid #007BFF',
                                        backgroundColor: selectedDiameters.includes(diameter) ? '#007BFF' : 'transparent',
                                    }}
                                />
                                <span>{diameter}</span>
                            </li>
                        ))}
                    </div>
                )}
            </div>
        



        </div>
    );
};

export default DropdownMenu;
